var site = site || {};
site.features = {
  has_auto_replenishment: true,
  has_checkout_samples: true,
  show_offer_entry_field: true,
  allows_anonymous_checkout: true,
  has_samples_only_checkout: false,
  search_type: 'endeca',
  uses_cheetah_mail_for_email_signup_email: false,
  uses_cheetah_mail_for_registration_email: false,
  personalization_is_enabled: false,
  use_frontend_device_detection: true,
  analytics_tagged: false,
  has_giftcards: false,
  has_waitlist_notification: false,
  has_favorites: false,
  has_pro_site: true,
  show_legal_acceptance_checkbox: false,
  has_loyalty_program: true,
  pre_check_optin: false
};
